import storefrontsJson from '$components/shopping/storefronts.json'
import { I18n } from '$services/i18n'
import type { JBGWeb } from '$types/JBGWeb'

export function getCurrencySymbol(currencyCode: string): string {
    switch (currencyCode) {
    case 'EUR':
        return '€'
    case 'GBP':
        return '£'
    case 'CAD':
    case 'MXN':
    case 'USD':
    default:
        return '$'
    }
}

// eslint-disable-next-line complexity
export function getPlatformInfo(key: string): string[] | null {
    if (!key) return null

    let displayText = ''
    let iconFileName = ''
    switch (key) {
    case 'steam':
        displayText = 'Steam'
        iconFileName = 'steam'
        return [displayText, iconFileName]
    case 'google':
        displayText = 'Google Play'
        iconFileName = 'google'
        return [displayText, iconFileName]
    case 'humble':
        displayText = 'Humble Bundle'
        iconFileName = 'humble'
        return [displayText, iconFileName]
    case 'nintendo':
        displayText = 'Nintendo Switch'
        iconFileName = 'nintendo-switch'
        return [displayText, iconFileName]
    case 'microsoft':
        displayText = 'Xbox'
        iconFileName = 'xbox'
        return [displayText, iconFileName]
    case 'sony':
        displayText = 'Playstation'
        iconFileName = 'ps'
        return [displayText, iconFileName]
    case 'apple':
        displayText = 'Apple TV & iPad'
        iconFileName = 'apple'
        return [displayText, iconFileName]
    case 'apple-mac':
        displayText = 'Mac App Store'
        iconFileName = 'mas'
        return [displayText, iconFileName]
    case 'epic':
        displayText = 'Epic Games Store'
        iconFileName = 'egs'
        return [displayText, iconFileName]
    case 'amazon':
        displayText = 'Amazon Fire TV'
        iconFileName = 'amazon'
        return [displayText, iconFileName]
    case 'luna':
        displayText = 'Amazon Luna'
        iconFileName = 'luna'
        return [displayText, iconFileName]
    default:
        // TODO: fallbacks text and icon?
        return [displayText, iconFileName]
    }
}

export function getStorefrontsFromProductData(product: JBGWeb.ProductDetail) {
    if (!product) return

    const platforms: JBGWeb.PlatformOption[] = []
    const shopifyData: JBGWeb.ShopifyData = product.shopifyData
    if (!shopifyData.data.product) return
    const shopifyMetafieldsArr: JBGWeb.ProductMetafield[] = shopifyData.data.product.metafields

    if (!shopifyMetafieldsArr) return console.warn('found no metafields on product')

    // for packs this should always just be an array of 1
    let fields: JBGWeb.ProductMetafieldFields[] = []
    shopifyMetafieldsArr.forEach((metafieldsObj) => {
        if (!metafieldsObj) return console.warn('found no fields in metafields object')
        fields = metafieldsObj.reference.fields
    })

    fields.forEach((field: JBGWeb.ProductMetafieldFields) => {
        const platform: JBGWeb.PlatformOption = {
            text: null,
            icon: null,
            vendor: null,
            price: null,
            currencyCode: null
        }

        platform.vendor = field.key

        // temporarily hide google for pack 10 until we have a storefront link
        if (product?.attributes.shopifyID === '6897951572102') {
            if (field.key === 'google') return
        }

        // destructure field.value
        // which comes in like this from shopify: "{\"amount\":\"29.99\",\"currency_code\":\"USD\"}"

        const json = JSON.parse(field.value)
        // do not include storefront when price is 0.0
        if (json.amount !== '0.0') {
            platform.price = Number(json.amount)
            platform.currencyCode = json.currency_code

            const platformInfo = getPlatformInfo(field.key)
            if (platformInfo) {
                platform.text = platformInfo[0]
                platform.icon = platformInfo[1]
            }

            platforms.push(platform)
        }
    })

    // sort platforms in order of sale priority (as determined by marketing)
    const sortingTemplate = ['nintendo', 'microsoft', 'sony', 'steam', 'apple', 'amazon', 'epic', 'humble']
    const sort = sortingTemplate.map((i) => platforms.find((j) => j.vendor === i))
    // not all packs will have a value for each entry in the sortingTemplate,
    // so remove all undefined entries.
    const sortedPlatforms = sort.filter((i) => i !== undefined)

    return sortedPlatforms
}

function getUID(platform: string, slug: string) {
    if (!storefrontsJson[platform].titles[slug]) return null
    const id = storefrontsJson[platform].titles[slug].id
    if (!id) return null
    return id
}

// eslint-disable-next-line complexity, max-len
export function getStorefrontLink(packSlug: string, slug: string, platform: string, fellBackToEn = false, isBundle = false): URL | undefined {
    if (!platform || !slug) return
    // remove localized slug endings; ex: '-de'
    // if (I18n.locale !== 'en' && !fellBackToEn) {
    //     slug = slug.slice(0, -(I18n.locale.length + 1))
    // }

    const UID = getUID(platform, slug)
    let url
    switch (platform) {
    case 'google':
        url = new URL(`${UID}`, storefrontsJson.google.baseUrl)
        break
    case 'humble':
        if (packSlug === 'drawful-2') {
            slug = 'drawful-two'
        }

        if (packSlug === 'the-jackbox-party-pack-1') {
            slug = 'the-jackbox-party-pack'
        }

        if (packSlug === 'fibbage') {
            slug = 'fibbage-xl'
        }

        url = new URL(slug, storefrontsJson.humble.baseUrl)
        break
    case 'steam':
        url = new URL(`app/${UID}/${slug.replace(/-/g, '_')}`, storefrontsJson.steam.baseUrl)
        // BUNDLES
        if (isBundle) {
            url = new URL(`bundle/${UID}/${slug.replace(/-/g, '_')}`, storefrontsJson.steam.baseUrl)
        }
        break
    case 'nintendo':
        url = new URL(`${slug}-switch`, storefrontsJson.nintendo.baseUrl)
        if (packSlug === 'the-jackbox-party-pack-1') {
            url = new URL('the-jackbox-party-pack-switch', storefrontsJson.nintendo.baseUrl)
        }
        if (packSlug === 'quiplash-2-interlashional') {
            url = new URL('quiplash-2-interlashional-the-say-anything-party-game-switch', storefrontsJson.nintendo.baseUrl)
        }

        if (packSlug === 'fibbage') {
            url = new URL('fibbage-xl-switch', storefrontsJson.nintendo.baseUrl)
        }

        break
    case 'microsoft':
        url = new URL(`${slug}/${UID}`, storefrontsJson.microsoft.baseUrl)
        if (packSlug === 'quiplash-2-interlashional') {
            url = new URL(`${slug}-the-say-anything-party-game/${UID}`, storefrontsJson.microsoft.baseUrl)
        }
        if (packSlug === 'fibbage') {
            url = new URL(`quiplash-2-interlashional-the-hilarious-bluffing-party-game/${UID}`, storefrontsJson.microsoft.baseUrl)
        }
        break
    case 'epic':
        slug = slug.slice(4)
        if (packSlug === 'the-jackbox-party-pack-10') {
            slug = 'the-jackbox-party-pack-10-c6f152'
        }

        if (packSlug === 'drawful-2') {
            slug = 'drawful-2'
        }

        if (packSlug === 'quiplash-2-interlashional') {
            slug = 'quiplash-2-interlashional-3c3888'
        }

        url = new URL(`${slug}`, storefrontsJson.epic.baseUrl)
        break
    case 'sony':
        if (slug === 'the-jackbox-party-pack-10' || slug === 'the-jackbox-party-pack-9') {
            url = new URL(`concept/${UID}`, storefrontsJson.sony.baseUrl)
        } else {
            url = new URL(`product/${UID}`, storefrontsJson.sony.baseUrl)
        }
        break
    case 'amazon':
        url = new URL(`dp/${UID}`, storefrontsJson.amazon.baseUrl)
        if (packSlug === 'fibbage') {
            url = new URL(`Fibbage-Hilarious-Bluffing-Party-Game/dp/${UID}`, storefrontsJson.amazon.baseUrl)
        }
        break
    case 'luna':
        url = new URL('https://www.amazon.com/luna/detail/B09R97C6ZG?ref_=jbx')
        break
    case 'apple':
        url = new URL(`${slug}/${UID}`, storefrontsJson.apple.baseUrl)
        break
    case 'mas':
        url = new URL(`${slug}/${UID}`, storefrontsJson.mas.baseUrl)
        break
    }
    return url
}

export function getShopifyLink(id: string) {
    let baseUrl = 'https://shop.jackboxgames.com/collections/staging-party-packs/products/'
    switch (I18n.locale) {
    case 'de':
        baseUrl = 'https://shop.jackboxgames.de/collections/staging-party-packs/products/'
        break
    case 'es':
        baseUrl = 'https://shop.jackboxgames.es/collections/staging-party-packs/products/'
        break
    case 'fr':
        baseUrl = 'https://shop.jackboxgames.fr/collections/staging-party-packs/products/'
        break
    case 'it':
        baseUrl = 'https://shop.jackboxgames.it/collections/staging-party-packs/products/'
        break
    case 'es-XL':
    case 'es-419':
        baseUrl = 'https://shop.jackboxgames.com/es/collections/staging-party-packs/products/'
        break
    case 'pt-BR':
    case 'pt-br':
        baseUrl = 'https://shop.jackboxgames.com/collections/staging-party-packs/products/'
        break
        // english
    default:
    }
    return new URL(`${id}`, baseUrl).toString()
}
